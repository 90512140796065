export default function EventCostIcons({ free, price, priceMin, classes, iconClasses }) {
  const priceRating = () => {
    switch (true) {
      case (price > 0 && price <= 50) || (priceMin > 0 && priceMin <= 50):
        return 1
      case (price > 0 && price > 50 && price <= 100) || (priceMin > 0 && priceMin > 50 && priceMin <= 100):
        return 2
      case (price > 0 && price > 100 && price <= 200) || (priceMin > 0 && priceMin > 100 && priceMin <= 200):
        return 3
      case (price > 0 && price > 200 && price <= 500) || (priceMin > 0 && priceMin > 200 && priceMin <= 500):
        return 4
      case (price > 0 && price > 500) || priceMin > 500:
        return 5
    }
  }

  return(
    <span className={`font-medium flex py-4 ${classes ? classes : ''}`}>
      {free &&
        <span className="text-bold">FREE</span>
      }
      {(price > 0 || priceMin > 0) && !free &&
        Array(priceRating()).fill("").map((icon, index) => {
          return <span className={iconClasses ? iconClasses : ''} key={`${index}-price-${Math.random}`}>$</span>
        })
      }
    </span>
  )
}