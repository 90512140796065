import { useRef } from 'react'
import Skeleton from 'react-loading-skeleton'
import { motion, useIsPresent } from "framer-motion"

export default function EventCardSkeleton({ feed }) {
  const cardRef = useRef(null)
  const isPresent = useIsPresent()

  // Animations
  const animations = {
    entering: {opacity: 0, y: -20},
    visible: {opacity: 1, y: 0},
    leaving: {opacity: 0}
  }
  const transition = {type: "spring", duration: 1, ease: "easeInOut" }

  return(
    <motion.div
      ref={cardRef}
      className={`mb-6 shadow-lg flex flex-col bg-white rounded-md ${!isPresent ? "hidden": ""}`}
      transition={transition}
      initial={animations.entering}
      animate={animations.visible}
      exit={animations.leaving}
      layout
    >
      <div className="aspect-square rounded-t-md w-full pb-full -mt-[4px]">
        <Skeleton width="100%" height="100%"/>
      </div>
      <div className="flex flex-col p-8">
        <p className="pb-4 uppercase font-barlow text-2xl"><Skeleton /></p>
        <div className="flex flex-col pb-4">
          <strong><Skeleton /></strong>
          <span className="text-sm mb-4"><Skeleton /></span>
          <strong><Skeleton /></strong>
          <span className="text-sm"><Skeleton /></span>
        </div>
      </div>
      {!feed &&
        <div className="flex items-center justify-around text-center mt-auto">
          <span className="button button-tertiary bg-mid-grey py-4 w-full border-none rounded-t-none rounded-b-md"><Skeleton /></span>
        </div>
      }
    </motion.div>
  )
}