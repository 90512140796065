const bikeTypes = [
  {
    title: 'Adventure/Dual-Sport',
    slug: 'adventure-dual-sport'
  },
  {
    title: 'V-Twin/Cruiser',
    slug: 'v-twin-cruiser'
  },
  {
    title: 'Dirt/Off-Road',
    slug: 'dirt-off-road'
  },
  {
    title: 'Scooter',
    slug: 'scooter'
  },
  {
    title: 'Sport/Street',
    slug: 'sport-street'
  },
  {
    title: 'Supermoto/Flat Track',
    slug: 'supermoto-flat-track'
  },
  {
    title: 'Touring',
    slug: 'touring'
  },
  {
    title: 'Trials',
    slug: 'trials'
  },
  {
    title: 'Vintage/Modern Classic',
    slug: 'vintage-modern-classic'
  },
  {
    title: 'Other',
    slug: 'other'
  },
]

export default bikeTypes