export function envPath() {
  const env = process.env.NODE_ENV

  switch(env) {
    case "development":
      return "http://localhost:3005"
    case "testing":
      return "https://testingwidget.kickstandsup.com"
    case "production":
      return "https://widget.kickstandsup.com"
  }
}