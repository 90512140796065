import { createContext, useContext, useEffect, useState } from 'react';
import { app } from '../firebase/firebaseConfig';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { useWidget } from './StateProvider';

// Create abalytics context
const AnalyticsContext = createContext({
  analytics: null
});

// Create analytics provider
const AnalyticsProvider = ({ children }) => {
  const [analytics, setAnalytics] = useState(null);
  const { widget } = useWidget();

  // Initialize analytics
  useEffect(() => {
    const initializeAnalytics = async () => {
      const analytics = await getAnalytics(app);
      setAnalytics(analytics);
    };
    if(window) {
      initializeAnalytics();
    }
  }, []);

  // Function to log events
  const log = async (eventName, data) => {
    try {
      await logEvent(analytics, eventName, data)
    } catch (error) {
      console.log(error)
    }
  }

  // Log loaded widget and its data on mount
  useEffect(() => {
    if(window && analytics && widget) {
      log("widget_loaded", {
        widgetId: widget.id,
        host: widget.hostName,
        timestamp: Date.now(),
        origin: window.location.origin,
        pathname: window.location.pathname,
      })
    }
  }, [analytics])

  return (
    <AnalyticsContext.Provider value={{ analytics, log }}>
      {children}
    </AnalyticsContext.Provider>
  );
}

const useAnalytics = () => {
  return useContext(AnalyticsContext)
}

export { AnalyticsProvider, useAnalytics}