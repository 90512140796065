import { radii } from "../../config/radii";
import Dropdown from "../Input/Dropdown";
import GoogleZipSearch from "../Input/GoogleZipSearch";
import { useWidgetState } from "../../providers/StateProvider";

export default function FilterLocation() {
  const { uiState, setUiState, queryState, setQueryState } = useWidgetState()

  const setLocationDisplay = (value) => {
    setUiState({
      ...uiState,
      locationDisplay: value
    })
  }

  const setLocationSearchTerms = (value) => {
    setUiState({
      ...uiState,
      locationSearchTerms: value
    })
  }

  return(
    <div className="relative">
      <Dropdown
        name="radius"
        id="radius"
        currentOption={queryState.radius}
        setCurrentOption={(radius) => {setQueryState({...queryState, radius, "page": 1})}}
        options={radii}
        textClasses="text-white flex"
        dropdownClasses="p-5 w-full rounded-t-md rounded-b-none h-full bg-darkest-grey border-darkest-grey hover:bg-dark-grey active:bg-dark-grey cursor-pointe transition ease-in-out duration-300"
      />
      <GoogleZipSearch
        locationSearchTerms={uiState.locationSearchTerms}
        setLocationSearchTerms={setLocationSearchTerms}
        locationData={queryState.locationData}
        setLocationData={(locationData) => {
          if(locationData) {
            setQueryState({...queryState, locationData, "page": 1})
          }
        }}
        classes="p-5 select-text bg-light-grey border border-light-grey w-full rounded-b-md" 
      />
    </div>
  )
}