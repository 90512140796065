import { useCallback } from "react";
import ReactPaginate from "react-paginate"
import { useWidgetState } from "../providers/StateProvider"
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";

export default function WidgetPagination() {
  const { eventResults, eventResultsError, queryState, setQueryState } = useWidgetState()

  console.log(queryState, 'WidgetPagination.jsx: state')

  // Handle react pagination interactions
  const paginationHandler = useCallback((page) => {
    setQueryState({ ...queryState, page: page.selected + 1 })
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [queryState]);

  return(
    <div>
      {!eventResultsError && eventResults?.events && eventResults?.events.length > 0 &&
        <ReactPaginate
          previousLabel={<FaAngleLeft/>}
          nextLabel={<FaAngleRight/>}
          breakLabel={'...'}
          breakClassName={'break-me'}
          activeClassName={'active'}
          containerClassName={'pagination'}
          subContainerClassName={'pages pagination'}
          initialPage={eventResults.page - 1}
          pageCount={Math.ceil(eventResults.found / queryState.pageSize)}
          pageRangeDisplayed={2}
          marginPagesDisplayed={1}
          onPageChange={paginationHandler}
          disableInitialCallback
        />
      }
    </div>
  )
}