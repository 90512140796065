import { useEffect, useState } from "react";
import ReactSlider from 'react-slider'

export default function FilterPrice({ query, setQuery }) {

  const priceRangeText = (priceRating) => {
    switch (priceRating) {
      case 1:
        return "Free"
      case 2:
        return "$50"
      case 3:
        return "$100"
      case 4:
        return "$200"
      case 5:
        return "$500"
      case 6:
        return "$500+"
    }
  }

  return(
    <div>
      <div className="py-4">
        <span>
          <span>{priceRangeText(query.priceMin)}</span>
          <span> - </span>
          <span>{priceRangeText(query.priceMax)}</span>
        </span>
      </div>
      <ReactSlider
        className="horizontal-slider double-slider"
        thumbClassName="example-thumb"
        trackClassName="example-track"
        defaultValue={[1, 6]}
        min={1}
        max={6}
        value={[query.priceMin, query.priceMax]}
        onChange={(value) => setQuery({...query, "priceMin": value[0], "priceMax": value[1]})}
        pearling
        minDistance={1}
      />
    </div>
  )
}