import './App.css';
import EventsWidget from './components/EventsWidget';
import StateProvider from './providers/StateProvider';
import { AnalyticsProvider } from './providers/AnalyticsProvider';
import { GoogleMapsProvider } from './providers/GoogleMapsProvider';

function App({ widgetId, widgetEnv }) {
  console.log(process.env.NODE_ENV)
  return (
    <StateProvider widgetId={widgetId} widgetEnv={widgetEnv}>
      <AnalyticsProvider>
        <GoogleMapsProvider>
          <div id='KsuEventsWidgetApp'>
            <EventsWidget widgetId={widgetId} widgetEnv={widgetEnv}  />
          </div>
        </GoogleMapsProvider>
      </AnalyticsProvider>
    </StateProvider>
  );
}

export default App;
