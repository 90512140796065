import { useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { FaTimes } from 'react-icons/fa'
import { useWidget } from '../providers/StateProvider'
import { useWidgetState } from '../providers/StateProvider'
import { useAnalytics } from '../providers/AnalyticsProvider'
import FilterRidingStyles from './WidgetFilters/FilterRidingStyles'
import FilterEventTypes from './WidgetFilters/FilterEventTypes'
import FilterEventSize from './WidgetFilters/FilterEventSize'
import FilterPrice from './WidgetFilters/FilterPrice'
import FilterLocation from './WidgetFilters/FilterLocation'
import FilterDateRange from './WidgetFilters/FilterDateRange'
import CurrentFilters from './WidgetFilters/CurrentFilters'
import Accordion from './UI/Accordion'
import { DateTime } from 'luxon'
import eventSize from '../config/eventSize'
import eventPrice from '../config/eventPrice'

export default function WidgetFilterMenu() {
  const { widget } = useWidget()
  const { uiState, setUiState, queryState, setQueryState, clearQuery } = useWidgetState()
  const { locationDisplay, locationSearchTerms } = uiState
  const { log } = useAnalytics()

  const overlayAnimation = {
    initial: {
      opacity: 0,
      transition: {
        duration: 0.2,
        ease: "easeInOut"
      }
    },
    animate: {
      opacity: 1,
      transition: {
        duration: 0.2,
        ease: "easeInOut"
      }
    },
    exit: {
      opacity: 0,
      transition: {
        duration: 0.2,
        ease: "easeInOut"
      }
    }
  }

  const menuAnimation = {
    initial: {
      x: '-100%',
      transition: {
        duration: 0.2,
        ease: "easeInOut"
      }
    },
    animate: {
      x: 0,
      transition: {
        duration: 0.2,
        ease: "easeInOut"
      }
    },
    exit: {
      x: '-100%',
      transition: {
        duration: 0.2,
        ease: "easeInOut"
      }
    }
  }

  const closeHandler = () => {
    setUiState({
      ...uiState,
      filterMenuOpen: false
    })

    try {
      log('filter_menu_closed', {
        action: 'close',
        category: 'filterMenu',
        label: 'close'
      })
    } catch (error) {
      console.log(error)
    }
  }

  const eventSizeText = (eventSizeQuery) => {
    switch (eventSizeQuery) {
      case 1:
        return eventSize[1]
      case 2:
        return eventSize[2]
      case 3:
        return eventSize[3]
      case 4:
        return eventSize[4]
      case 5:
        return ""
    }
  }

  const eventPriceText = (eventPriceQuery) => {
    switch (eventPriceQuery) {
      case 1:
        return eventPrice[1]
      case 2:
        return eventPrice[2]
      case 3:
        return eventPrice[3]
      case 4:
        return eventPrice[4]
      case 5:
        return eventPrice[5]
      case 6:
        return ""
    }
  }

  // Date range state
  const [ dateRange, setDateRange ] = useState(() => {
    let dateArray = [
      queryState.start ? new DateTime.fromSeconds(queryState.start).toJSDate() : null,
      queryState.end ? new DateTime.fromSeconds(queryState.end).toJSDate() : null
    ]

    return dateArray
  })

  // Formatted date range state
  const [ dateRangeDisplay, setDateRangeDisplay ] = useState(() => {
    if(queryState.start && queryState.end) {
      const start = DateTime.fromJSDate(dateRange[0]).toLocaleString(DateTime.DATE_SHORT)
      const end = DateTime.fromJSDate(dateRange[1]).toLocaleString(DateTime.DATE_SHORT)

      return `${start} - ${end}`
    } else {
      return ""
    }
  })



  return(
    <div className={`fixed z-[100] top-0 left-0 w-full h-full ${uiState?.filterMenuOpen ? 'pointer-events-auto' : 'pointer-events-none'}`}>
      <AnimatePresence>
        {uiState?.filterMenuOpen &&
          <motion.div
            initial={overlayAnimation.initial}
            animate={overlayAnimation.animate}
            exit={overlayAnimation.exit}
            className='absolute w-full h-full bg-black-opaque-dark'
          />
        }
      </AnimatePresence>
      <AnimatePresence>
        {uiState?.filterMenuOpen &&
          <motion.nav
            initial={menuAnimation.initial}
            animate={menuAnimation.animate}
            exit={menuAnimation.exit}
            className='absolute left-0 top-0 h-full w-full lg:max-w-[400px] bg-off-black shadow-md overflow-y-auto'
          >
            <div className="bg-off-black text-white">
              <div className="w-full">
                <div className="flex justify-between">
                  <span className="font-barlow text-3xl p-4 text-center">FILTERS</span>
                  <button onClick={closeHandler} className="text-xl p-4"><FaTimes/></button>
                </div>
                <button onClick={clearQuery} className="underline text-yammy-25 p-4 text-left lg:order-2 lg:text-right">Clear All</button>
              </div>
              {/* <div>
                <CurrentFilters
                  widget={widget}
                  query={queryState}
                  setQuery={setQueryState}
                  locationDisplay={uiState.locationDisplay}
                  setLocationDisplay={setLocationDisplay}
                  dateRangeDisplay={dateRangeDisplay}
                  setDateRangeDisplay={setDateRangeDisplay}
                  setLocationSearchTerms={setLocationSearchTerms}
                />
              </div> */}
              <div>
                <Accordion title="LOCATION" altCurrentValueEnabled altCurrentValue={locationDisplay} contentClasses="bg-white text-black">
                  <div className="flex flex-col px-8 py-5">
                    <FilterLocation
                      widget={widget}
                      query={queryState}
                      setQuery={setQueryState}
                    />
                  </div>
                </Accordion>
                <Accordion title="DATE" altCurrentValueEnabled altCurrentValue={dateRangeDisplay} contentClasses="bg-white text-black">
                  <div className="flex flex-col px-8 py-5">
                    <FilterDateRange
                      widget={widget}
                      query={queryState}
                      setQuery={setQueryState}
                      dateRange={dateRange}
                      setDateRange={setDateRange}
                      dateRangeDisplay={dateRangeDisplay}
                      setDateRangeDisplay={setDateRangeDisplay}
                    />
                  </div>
                </Accordion>
                {widget?.ridingStyles.length > 0 &&
                  <Accordion title="RIDING STYLES" selectedCount={queryState.ridingStyles.length} contentClasses="bg-white text-black">
                    <div className="flex flex-col px-8 py-5">
                      <FilterRidingStyles widget={widget} query={queryState} setQuery={setQueryState} />
                    </div>
                  </Accordion>
                }
                {widget?.eventTypes.length > 0 &&
                  <Accordion title="EVENT TYPES" selectedCount={queryState.eventTypes.length} contentClasses="bg-white text-black">
                    <div className="flex flex-col px-8 py-5">
                      <FilterEventTypes widget={widget} query={queryState} setQuery={setQueryState} />
                    </div>
                  </Accordion>
                }
                <Accordion title="EVENT SIZE" currentValueEnabled currentValue={eventSizeText(queryState.eventSize)} contentClasses="bg-white text-black">
                  <div className="flex flex-col px-8 py-5">
                    <FilterEventSize widget={widget} query={queryState} setQuery={setQueryState} />
                  </div>
                </Accordion>
                <Accordion title="PRICE" currentValueEnabled currentValue={eventPriceText(queryState.price)} contentClasses="bg-white text-black">
                  <div className="flex flex-col px-8 py-5 rounded-b-md">
                    <FilterPrice widget={widget} query={queryState} setQuery={setQueryState} />
                  </div>
                </Accordion>
              </div>
              <div className="sticky bottom-0 w-full bg-black z-[200]">
                <button onClick={closeHandler} className="button button-tertiary font-bold text-xl w-full rounded-none p-4">Apply Filters</button>
              </div>
            </div>
          </motion.nav>
        }
      </AnimatePresence>
    </div>
  )
}