import { useEffect, useState } from "react"
import usePlacesAutocomplete, { getGeocode, getLatLng } from "use-places-autocomplete"

export default function GoogleZipSearch({ locationData, setLocationData, locationSearchTerms, setLocationSearchTerms, classes, disabled }) {
  const {
    value,
    suggestions: { status, data, loading },
    setValue,
    clearSuggestions } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: { country: ["us", "mx", "ca"] },
      types: ['(regions)']
    },
    debounce: 800,
  })
  const [selectedLocation, setSelectedLocation] = useState(locationData ? locationData : null)
  const [locationGeocode, setLocationGeocode] = useState(null)
  const [locationLatLng, setLocationLatLng] = useState(null)

  useEffect(() => {
    if(!locationSearchTerms) {
      setValue(locationSearchTerms)
      setSelectedLocation(null)
      setLocationGeocode(null)
      setLocationLatLng(null)
    } else {
      setValue(locationSearchTerms)
    }
  }, [locationSearchTerms])

  const handleInput = event => {
    setSelectedLocation(null)
    setLocationGeocode(null)
    setLocationLatLng(null)
    setLocationData(null)
    setLocationSearchTerms(event.currentTarget.value)
  }

  // Get geocode data from selected location
  const handleSelect = suggestion => {
    setSelectedLocation(suggestion)
    clearSuggestions()

    getGeocode({placeId: suggestion.place_id}).then(res => {
      console.log(res)
      setLocationGeocode(res[0])
    }).catch(error => console.error(error))
  }

  // Get latitude & longitude if valid geocode data is provided
  useEffect(() => {
    if(locationGeocode) {
      try {
        const { lat, lng } = getLatLng(locationGeocode)
        setLocationLatLng({lat, lng})
      } catch (error) {
        console.log(error)
      }
    }
  }, [locationGeocode])

  // If all data is present, build final data object
  useEffect(() => {
    if(selectedLocation, locationGeocode, locationLatLng) {
      let data = {
        ...selectedLocation,
        address_components: locationGeocode.address_components,
        formatted_address: locationGeocode.formatted_address,
        coordinates: locationLatLng
      }
      setLocationData(data)
    }
  }, [selectedLocation, locationGeocode, locationLatLng])

  return(
    <div className="relative">
      <input
        className={classes ? `${classes}` : "bg-dark-grey p-4 rounded-md w-full disabled:opacity-25"}
        type="text"
        name="Event Location"
        id="eventLocation"
        placeholder="Enter a City, State, or Zip..."
        autoComplete="off"
        autoFocus={true}
        disabled={disabled}
        value={selectedLocation ? selectedLocation.description : locationSearchTerms}
        onInput={event => handleInput(event)} />
      { value &&
        <div className="mt-4 w-full rounded-md bg-white text-black shadow-xl">
          { value.length && !status && !selectedLocation &&
            <div className="flex p-4">
              <img src={`/assets/load-wheel.svg`} alt="loading icon" width="30px" height="30px" className="animate-spin" />
              <span className="text-lg ml-4 font-bold">Searching...</span>
            </div>
          }
          { status === "ZERO_RESULTS" && <span className="block p-4 text-lg font-bold">No results. Try another search!</span> }
          { status === "OK" && data.map(suggestion =>
              <div
                key={suggestion.place_id}
                onClick={() => handleSelect(suggestion)}
                className="flex flex-col p-4 hover:bg-kato-100 hover:text-white first:rounded-t-md last:rounded-b-md cursor-pointer transition ease-linear duration-100">
                <span className="font-bold text-lg">{suggestion.structured_formatting.main_text}</span>
                <span>{suggestion.structured_formatting.secondary_text}</span>
              </div>
            )
          }
        </div>
      }
    </div>
  )
}