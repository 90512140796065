import { useEffect, useState } from "react"
import { useWidgetState } from "../../providers/StateProvider"
import { FaList } from "react-icons/fa"

export default function SortDropdown() {
  const { queryState, setQueryState } = useWidgetState()
  const [ currentSort, setCurrentSort ] = useState(queryState.sort)

  useEffect(() => {
    setCurrentSort(queryState.sort)
  }, [queryState.sort])

  const handleSort = (event) => {
    setQueryState({...queryState, "sort": event.target.value})
    setCurrentSort(event.target.value)
  }

  return(
    <label className="font-barlow text-xl uppercase text-center flex-col items-start lg:justify-end lg:items-end ml-4" htmlFor="sortBy">
      <div className="relative">
        <select value={currentSort} onChange={handleSort} name="sortBy" id="sortBy" className="bg-dark-grey p-4 pt-7 pb-2 rounded-md font-plex text-base appearance-none">
          <option value="start:asc">Date Ascending</option>
          <option value="start:desc">Date Descending</option>
          <option value="price:asc,priceMin:asc">Price Lowest</option>
          <option value="price:desc,priceMin:desc">Price Highest</option>
          <option value="eventSize:asc">Size Smallest</option>
          <option value="eventSize:desc">Size Largest</option>
        </select>
        <span className="inline-flex items-center text-base absolute left-0 px-4 py-2 pointer-events-none"><strong className="mr-2">Sort By</strong><FaList/></span>
      </div>
    </label>
  )
}