const eventTypes = [
  {
    title: "Bike Night/Meetup",
    slug: "bike-night-meetup"
  },
  {
    title: "Bike Show",
    slug: "bike-show"
  },
  {
    title: "Campout",
    slug: "campout"
  },
  {
    title: "Charity/Benefit",
    slug: "charity-benefit"
  },
  {
    title: "Demo",
    slug: "demo"
  },
  {
    title: "Expo",
    slug: "expo"
  },
  {
    title: "Industry/Trade",
    slug: "industry-trade"
  },
  {
    title: "Race/Competition",
    slug: "race-competition"
  },
  {
    title: "Rally",
    slug: "rally"
  },
  {
    title: "Ride",
    slug: "ride"
  },
  {
    title: "Spectate Only",
    slug: "spectate-only"
  },
  {
    title: "Swap Meet",
    slug: "swap-meet"
  },
  {
    title: "Training/Safety",
    slug: "training-safety"
  },
  {
    title: "Trip/Tour",
    slug: "trip-tour"
  },
  {
    title: "Women Focused",
    slug: "women-focused"
  }
]

export default eventTypes