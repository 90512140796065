import { useCallback, useMemo, useState } from "react";
import { FaSearch, FaTimes } from "react-icons/fa";
import { useWidgetState } from "../../providers/StateProvider";
import debounce from "lodash.debounce";

export default function SearchInput() {
  const { uiState, setUiState } = useWidgetState()
  const [ input, setInput ] = useState("")

  const debouncedSearchInput = useCallback(
    debounce((value) => {
      setUiState({
        ...uiState,
        userSearchText: value
      })
    }, 500)
  , [])

  const debouncedSearchInputHandler = (e) => {
    setInput(e.target.value)
    debouncedSearchInput(e.target.value)
  }

  const clearSearch = () => {
    setInput("")
    setUiState({
      ...uiState,
      userSearchText: ""
    })
  }

  return(
    <div className="relative">
      <div className="text-off-black absolute left-0 top-0 p-4">
        <FaSearch/>
      </div>
      <input value={input} onInput={debouncedSearchInputHandler} type="text" className="w-full h-12 py-4 px-12 text-lg text-black rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-kato-100 focus:border-transparent" placeholder="Search for events"/>
      <button onClick={clearSearch} className="text-dark-grey absolute right-0 top-0 p-4">
        <FaTimes/>
      </button>
    </div>
  )
}