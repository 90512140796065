import eventTypes from "../../config/eventTypes"
import Checkbox from "../Input/Checkbox"

export default function FilterEventTypes({ widget, query, setQuery }) {

  const handleEventTypes = async (eventType) => {
    const eventTypes = query.eventTypes

    if(eventTypes.some(style => style === eventType)) {
      setQuery({...query, "eventTypes": eventTypes.filter(style => style !== eventType), "page": 1})
    } else {
      setQuery({...query, "eventTypes": [...eventTypes, eventType], "page": 1})
    }
  }

  const eventTypeIsChecked = (eventType) => {
    return query.eventTypes.includes(eventType)
  }

  return(
    <>
      {eventTypes.map(event => {
        if(widget.eventTypes.includes(event.title)) {
          return(
            <div key={event.slug} className="pb-3">
              <Checkbox
              id={event.slug}
              label={event.title}
              callback={() => handleEventTypes(event.title)}
              checked={eventTypeIsChecked(event.title)}/>
            </div>
          )
        }
      })}
    </>
  )
}