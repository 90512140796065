import { useWidgetState } from "../providers/StateProvider"
import WidgetEventCard from "./WidgetEventCard"
import EventCardSkeleton from "./WidgetEventCard/EventCardSkeleton"
import { AnimatePresence } from "framer-motion"

export default function WidgetEvents() {
  const { eventResults, eventResultsError } = useWidgetState()

  console.log(eventResults, 'WidgetEvents.jsx: events')

  return(
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
      {eventResults && eventResults?.events && eventResults?.events.length > 0 &&
        <AnimatePresence>
          {eventResults.events.map((event, index) => {
            return(
              <WidgetEventCard key={index} event={event}/>
            )
          })}
        </AnimatePresence>
      }
      {!eventResults &&
        <AnimatePresence popLayout>
          {Array(8).fill("").map(() =>
            <EventCardSkeleton key={`skeleton-event-card-${Math.random() * Math.random()}`} feed={true}/>
          )}
        </AnimatePresence>
      }
      {eventResults && eventResults?.events.length === 0 &&
        <h2>No events found</h2>
      }
      {eventResultsError &&
        <h1>error</h1>
      }
    </div>
  )
}