import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';

// Get ID from URL if loading independently
const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const queryId = urlParams?.get('id') || null;
const queryEnv = urlParams?.get('env') || null;

const domNode = document.getElementById("KsuWidgetRoot");
const widgetId = domNode?.dataset?.widgetId || queryId || null;
const widgetEnv = domNode?.dataset?.widgetEnv || queryEnv || null;
const root = createRoot(domNode);

root.render(<App widgetId={widgetId} widgetEnv={widgetEnv} />)
