import { createContext } from "react"
import { Wrapper } from '@googlemaps/react-wrapper'
import { useWidgetState } from "./StateProvider"

export const GoogleMapsContext = createContext()

export function GoogleMapsProvider({children}) {
  const { widgetEnv } = useWidgetState()

  return(
    <GoogleMapsContext.Provider value={null}>
      {widgetEnv === "preview" ?
        <>
          {children}
        </>
        :
        <Wrapper
          apiKey={process.env.REACT_APP_FIREBASE_API_KEY}
          libraries={["places"]}
        >
          {children}
        </Wrapper>
      }
    </GoogleMapsContext.Provider>
  )
}