import { FaMale } from "react-icons/fa"

export default function EventCostIcons({ size, classes, iconClasses }) {

  const sizeRating = () => {
    switch (true) {
      case (size > 0 && size <= 25):
        return 1
      case (size > 25 && size <= 50):
        return 2
      case (size > 50 && size <= 100):
        return 3
      case (size > 100 && size <= 1000):
        return 4
      case (size > 1000):
        return 5
    }
  }

  return(
    <span className={`py-4 mr-4 flex ${classes ? classes : ''}`}>
      {size > 0 &&
        Array(sizeRating()).fill("").map((icon, index) => {
          return <span className={iconClasses ? iconClasses : ''} key={`${index}-size`}><FaMale/></span>
        })
      }
    </span>
  )
}