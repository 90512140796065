import { FaFilter } from 'react-icons/fa'
import { useWidget, useWidgetState } from '../providers/StateProvider'
import { useAnalytics } from '../providers/AnalyticsProvider'
import SearchInput from './Input/SearchInput'
import SortDropdown from './Input/SortDropdown'

export default function WidgetMenu() {
  const { widget } = useWidget()
  const { uiState, setUiState } = useWidgetState()
  const { log } = useAnalytics()

  // Handler for filter button
  const filterButtonHandler = () => {
    setUiState({
      ...uiState,
      filterMenuOpen: !uiState.filterMenuOpen
    })

    try {
      log("widget_filter_menu_opened", {
        widgetId: widget.id,
        host: widget.hostName,
        timestamp: Date.now(),
        origin: window.location.origin,
        pathname: window.location.pathname,
      })
    } catch (error) {
      console.log(error)
    }
  }

  return(
    <nav className="bg-off-black w-full">
      <div className="flex flex-row items-center justify-between w-full text-white shadow-md font-barlow font-normal uppercase p-4">
        {widget?.type !== "host" &&
          <button onClick={filterButtonHandler} className="flex items-center uppercase text-xl text-white p-4 bg-kato-100 rounded-md">
            <FaFilter className="mr-2 text-base"/>
            <span>Filters</span>
          </button>
        }
        {widget?.type === "host" &&
          <h1 className='h3 m-0 p-0'>Events</h1>
        }
        <div className='flex items-center ml-auto'>
          <SearchInput/>
          <SortDropdown />
        </div>
      </div>
    </nav>
  )
}