import { useWidgetState } from "../providers/StateProvider"
import { AnimatePresence, motion } from "framer-motion"
import { FaTimes } from "react-icons/fa"
import EventDateTimeCard from "./WidgetEventCard/EventDateTimeCard"
import EventCostIcons from "./WidgetEventCard/EventCostIcons"
import EventAttendanceIcons from "./WidgetEventCard/EventAttendanceIcons"

export default function WidgetEventDrawer() {
  const { uiState, setUiState } = useWidgetState()
  const { currentEvent } = uiState

  const overlayAnimations = {
    initial: {
      opacity: 0,
    },
    animate: {
      opacity: 1,
    },
    exit: {
      opacity: 0,
    }
  }

  const drawerAnimations = {
    initial: {
      x: "100%",
    },
    animate: {
      x: 0,
    },
    exit: {
      x: "100%",
    }
  }

  return(
    <motion.div initial={overlayAnimations.initial} animate={overlayAnimations.animate} exit={overlayAnimations.exit} className="fixed z-[100] top-0 right-0 w-screen h-screen bg-black-opaque-dark bg-opacity-50">
      <AnimatePresence>
        {currentEvent &&
          <motion.div initial={drawerAnimations.initial} animate={drawerAnimations.animate} exit={drawerAnimations.exit} className="relative ml-auto z-50 bg-white w-full h-screen max-w-[780px]">
            <div className="bg-off-black flex justify-end text-white">
              <button onClick={() => setUiState({...uiState, currentEvent: null})} className="p-4 text-xl"><FaTimes/></button>
            </div>
            <div className="relative h-full">
              <div className="image-container overflow-hidden max-h-[300px] h-full">
                <img src={currentEvent.eventImages.bannerImg} alt={currentEvent.eventName} layout="fill"  placeholder="blur" />
              </div>
              <div className="w-full bg-darkest-grey text-white uppercase font-barlow grid grid-flow-col">
                {/* <Link href={`/events/${currentEvent.eventSlug}`}>
                  <a className="button button-tertiary rounded-none flex items-center"><EyeIcon className="mr-2"/> View Event</a>
                </Link> */}
              </div>
              <div className="p-4">
                <p className="pb-2 uppercase font-barlow text-2xl"><strong>{currentEvent.eventName}</strong></p>
                {currentEvent.eventLocationDescription &&
                  <p className="pb-2 text-sm">{currentEvent.eventLocationDescription ? currentEvent.eventLocationDescription.replace(", USA", "") : ""}</p>
                }
                <div className="mb-2 flex items-center text-dark-grey">
                  <EventCostIcons free={currentEvent.free} price={currentEvent.price} priceMin={currentEvent.priceMin} classes="-mt-0.5 text-base mr-2" iconClasses="" />
                  <EventAttendanceIcons size={currentEvent.eventSize} classes="text-lg ml-3" iconClasses="-ml-2" />
                </div>
                <EventDateTimeCard start={currentEvent.start} end={currentEvent.end} timezone={currentEvent.timezone} />
              </div>
              <div className="absolute bottom-0 right-0 w-full -translate-y-full">
                <a className="button button-primary" href={``} target="_blank">View Full Details</a>
              </div>
            </div>
          </motion.div>
        }
      </AnimatePresence>
    </motion.div>
  )
}