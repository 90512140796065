import { useEffect } from "react"
import DateRangePicker from '@wojtekmaj/react-daterange-picker'
import { DateTime } from "luxon"

export default function FilterDateRange({ query, setQuery, dateRange, setDateRange, dateRangeDisplay, setDateRangeDisplay }) {

  useEffect(() => {
    if(dateRange && dateRange.length && dateRange[0] && dateRange[1]) {
      const start = DateTime.fromJSDate(dateRange[0]).toLocaleString(DateTime.DATE_SHORT)
      const end = DateTime.fromJSDate(dateRange[1]).toLocaleString(DateTime.DATE_SHORT)
      setDateRangeDisplay(`${start} - ${end}`)
      setQuery({
        ...query,
        "start": DateTime.fromJSDate(dateRange[0]).toUnixInteger(),
        "end": DateTime.fromJSDate(dateRange[1]).toUnixInteger(),
        "page": 1
      })
    } else {
      setDateRangeDisplay("")
      if(query.start && query.end) {
        setQuery({...query, "start": null, "end": null, "page": 1})
      }
    }
  }, [dateRange])

  useEffect(() => {
    if(dateRangeDisplay === "" && !query.start && !query.end) {
      setDateRange([null, null])
    }
  }, [dateRangeDisplay])


  return(
    <div className="flex min-h-[400px]">
      <DateRangePicker  onChange={setDateRange} value={dateRange} isOpen={true} closeCalendar={false} minDate={new Date()} />
    </div>
  )
}