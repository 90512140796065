import { useState } from "react";
import { FaAngleDown } from "react-icons/fa";
import { motion, AnimatePresence } from "framer-motion"

export default function Accordion({ children, title, currentValue, currentValueEnabled, open, selectedCount, altCurrentValue, altCurrentValueEnabled, titleClasses, contentClasses }) {
  const [ isOpen, setIsOpen ] = useState(open ? open : false)

  return(
    <div className="overflow-hidden select-none">
      <div className={`w-full px-8 py-5 hover:bg-darkest-grey active:bg-darkest-grey cursor-pointe transition ease-in-out duration-300 ${titleClasses ? titleClasses : ""}`} onClick={() => setIsOpen(!isOpen)}>
        <div className="flex justify-between">
          <span className="flex items-center font-barlow uppercase text-xl">
            <strong>{title}</strong>
            <span className="ml-2 text-light-grey font-plex text-base">{selectedCount ? `(${selectedCount})` : ""}</span>
            {currentValueEnabled && currentValue &&
              <span className="ml-2 text-light-grey font-plex text-base">{currentValue}</span>
            }
          </span>
          <div>
            <FaAngleDown className={`transform-gpu transition ease-in-out duration-200 ${isOpen ? "rotate-180" : "rotate-0"}`}/>
          </div>
        </div>
        {altCurrentValueEnabled && altCurrentValue &&
          <div className="py-4">
            <span className="mr-4 text-light-grey">{altCurrentValue}</span>
          </div>
        }
      </div>
      <AnimatePresence>
        {isOpen &&
          <motion.div
            className={`border-b border-b-mid-grey shadow-inner ${contentClasses ? contentClasses : ""}`}
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: "auto" },
              collapsed: { opacity: 0, height: 0 }
            }}
            transition={{type: "spring", duration: 0.2, ease: "easeInOut" }}>
            {children}
          </motion.div>
        }
      </AnimatePresence>
    </div>
  )
}