import _kebabCase from 'lodash/kebabCase'
import { FaAngleDown } from 'react-icons/fa'

export default function Dropdown({name, id, options, textClasses, dropdownClasses, currentOption, setCurrentOption}) {

  const handleCurrentOption = event => {
    let currentOption = options.find(option => option.value === event.currentTarget.value)
    setCurrentOption(currentOption)
  }

  return(
    <div className={textClasses ? `${textClasses} relative` : "text-black relative"}>
      <select
        className={dropdownClasses ? `${dropdownClasses} w-full p-2 appearance-none rounded-md` : "w-full p-2 appearance-none rounded-md"}
        name={name}
        id={id}
        placeholder={currentOption && currentOption.name ? currentOption.name : "Choose an Option"}
        value={currentOption.value}
        onChange={ event => handleCurrentOption(event) }>
        {options.map((option, index) => {
          return(
            <option
              key={`${_kebabCase(option.value)}-${index}`}
              value={option.value}>
                {option.name}
            </option>
          )
        })}
      </select>
      <FaAngleDown className='absolute top-0 right-0 p-2 h-full flex items-center pointer-events-none' />
    </div>
  )
}