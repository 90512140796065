import { DateTime } from 'luxon'

export default function EventDateTimeCard({ start, end }) {
  const isSameDay = () => {
    const startDateTime = DateTime.fromSeconds(start)
    const endDateTime = DateTime.fromSeconds(end)

    return startDateTime.day === endDateTime.day
  }
  return(
    <div className='flex flex-col'>
      {isSameDay() &&
        <>
          <div className='flex flex-col mb-4'>
            <span className='font-medium'>
              {DateTime.fromSeconds(start).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)}
            </span>
            <span>
              {DateTime.fromSeconds(start).toLocaleString(DateTime.TIME_SIMPLE)}
              <span> - </span>
              {DateTime.fromSeconds(end).toLocaleString(DateTime.TIME_SIMPLE)}
            </span>
          </div>
        </>
      }
      {!isSameDay() &&
        <>
          <div className='flex flex-col mb-4 mr-8'>
            <span>
              <span className='font-medium mr-2'>Starts:</span>{DateTime.fromSeconds(start).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)}
              <br/>
              <span className='font-medium mr-2'>Ends:</span>{DateTime.fromSeconds(end).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)}
            </span>
          </div>
        </>
      }
    </div>
  )
}