import { useEffect, useState } from "react"
import { FaMale } from "react-icons/fa"
import ReactSlider from 'react-slider'

export default function FilterEventSize({ query, setQuery }) {

  const eventSizeText = (eventSizeQuery) => {
    switch (eventSizeQuery) {
      case 1:
        return "10"
      case 2:
        return "50"
      case 3:
        return "100"
      case 4:
        return "1000"
      case 5:
        return "1000+"
      default:
      return "10"
    }
  }

  return(
    <div>
      <div className="pb-4">
        <span>
          <span>{eventSizeText(query.sizeMin)}</span>
          <span> - </span>
          <span>{eventSizeText(query.sizeMax)}</span>
        </span>
      </div>
      <ReactSlider
        className="horizontal-slider double-slider"
        thumbClassName="example-thumb"
        trackClassName="example-track"
        defaultValue={[1, 5]}
        min={1}
        max={5}
        value={[query.sizeMin, query.sizeMax]}
        onChange={(value) => setQuery({...query, "sizeMin": value[0], "sizeMax": value[1]})}
        pearling
        minDistance={1}
      />
    </div>
  )
}