import React from 'react'
import { FaCheck } from 'react-icons/fa'

export default function Checkbox({ id, label, callback, checked, paddingDisabled }) {

  const handleChecked = async event => {
    callback && callback()
  }

  return(
    <label role="checkbox" aria-checked={checked} aria-labelledby={id} tabIndex="0" onChange={event => handleChecked(event)} className={`flex items-center ${ paddingDisabled ? "" : "pr-4 pb-4" }`} htmlFor={id}>
      <input checked={checked} className="hidden" type="checkbox" id={id} onChange={handleChecked}/>
      <div className="w-5 h-5 shrink-0 border bg-white text-black rounded-sm flex items-center justify-center mr-3">
        { checked && <FaCheck/> }
      </div>
      <span className="font-bold select-none">{label}</span>
    </label>
  )
}