import { FaInfoCircle } from "react-icons/fa"
import EventDateTimeCard from "./WidgetEventCard/EventDateTimeCard"
import EventCostIcons from "./WidgetEventCard/EventCostIcons"
import EventAttendanceIcons from "./WidgetEventCard/EventAttendanceIcons"
import { useWidgetState } from "../providers/StateProvider"
import { useCallback, useRef } from "react"
import { motion } from "framer-motion"
import ProgressiveImg from "./UI/ProgressivImg"

export default function WidgetEventCard({ event }) {
  const cardRef = useRef(null)
  const { uiState, setUiState } = useWidgetState()
  const { id, eventName, start, end, timezone, eventSlug, eventImages, free, price, priceMin, eventSize, hostName, hostSlug, cohostName, cohostSlug, eventLocationDescription, isCancelled } = event

  const selectEvent = useCallback(() => {
    setUiState({...uiState, currentEvent: event })
  }, [uiState])

  // Animations
  const animations = {
    entering: {opacity: 0, y: -20},
    visible: {opacity: 1, y: 0},
    leaving: {opacity: 0}
  }
  const transition = {type: "spring", duration: 1, ease: "easeInOut" }

  return(
    <motion.div
      ref={cardRef}
      className="relative shadow-lg flex rounded-md h-full cursor-pointer"
      onClick={() => selectEvent(event)}
      transition={transition}
      initial={animations.entering}
      animate={animations.visible}
      exit={animations.leaving}
    >
      <div className="flex flex-col bg-white w-full rounded-md">
        <div className="w-full aspect-square rounded-t-md bg-mid-grey">
          <img className="rounded-t-md w-full h-full object-cover object-center z-0 bg-shimmer" src={eventImages.feedImg} alt={`${eventName} feed image`} width={500} height={500} />
        </div>
        <div className="flex flex-col p-4 lg:p-8">
          <div className="mb-2">
            <p className="pb-2 uppercase font-barlow text-2xl"><strong>{eventName}</strong></p>
            <p className="pb-2 text-sm">{eventLocationDescription ? eventLocationDescription.replace(", USA", "") : ""}</p>
            <div className="mb-2 flex items-center text-dark-grey">
              <EventCostIcons free={free} price={price} priceMin={priceMin} classes="-mt-0.5 text-base mr-2" iconClasses="" />
              <EventAttendanceIcons size={eventSize} classes="text-lg ml-3" iconClasses="-ml-2" />
            </div>
            {isCancelled ?
              <span className="text-red">Cancelled</span>
              :
              <EventDateTimeCard start={start} end={end} timezone={timezone} />
            }
          </div>
          <div className="text-sm flex flex-col">
            <span>Host: <strong className="ml-2">{hostName}</strong></span>
            {cohostName &&
              <span>Co-Host: <strong className="ml-2">{cohostName}</strong></span>
            }
          </div>
        </div>
        <div className="mt-auto">
          <button className="button button-primary w-full rounded-t-none"><FaInfoCircle className="mr-2"/> More Info</button>
        </div>
      </div>
    </motion.div>
  )
}